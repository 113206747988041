// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._46W9MHsW_JJ4Gaf3RfR{font-size:18px;font-weight:500}._46W9MHsW_JJ4Gaf3RfR.PPBLt62CA0aGPkSOgUgK{font-size:20px;font-weight:600}._46W9MHsW_JJ4Gaf3RfR.cOLclCGsPXFGMMl4uTD_{font-size:22px;font-weight:800}`, "",{"version":3,"sources":["webpack://./src/common/components/Typography/components/Title/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CAEA,2CACE,cAAA,CACA,eAAA,CAGF,2CACE,cAAA,CACA,eAAA","sourcesContent":[".main {\n  font-size: 18px;\n  font-weight: 500;\n\n  &.sizeMedium {\n    font-size: 20px;\n    font-weight: 600;\n  }\n\n  &.sizeLarge {\n    font-size: 22px;\n    font-weight: 800;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var main = `_46W9MHsW_JJ4Gaf3RfR`;
export var sizeMedium = `PPBLt62CA0aGPkSOgUgK`;
export var sizeLarge = `cOLclCGsPXFGMMl4uTD_`;
export default ___CSS_LOADER_EXPORT___;
