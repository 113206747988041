import { Navigate, RouteObject } from 'react-router-dom';

import { CreateRecordView } from '@/features/records/views/index.ts';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/records/create" />,
    index: true
  },
  {
    path: '/records/create',
    element: <CreateRecordView />
  }
];

export { routes };
