import { Center, Image, Header as MantineHeader, Text } from '@mantine/core';

import AppLogoSvg from '@/assets/logos/logo.svg';

const Header = () => {
  return (
    <MantineHeader height="50px" bg="bloodred.8">
      <Center px="sm" h="100%" w="100%" pos="relative">
        <Image
          src={AppLogoSvg}
          height="40px"
          width="40px"
          pos="absolute"
          left="10px"
        />
        <Text color="white">Exani</Text>
      </Center>
    </MantineHeader>
  );
};

export { Header };
