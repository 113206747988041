import {
  AppShell,
  DefaultMantineColor,
  MantineProvider,
  MantineThemeOverride,
  Tuple
} from '@mantine/core';
import { Outlet } from 'react-router-dom';

import { Header } from './common/components/index.ts';

type AppColorNames = 'bloodred' | 'white' | 'black' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, Tuple<string, 10>>;
  }
}

const AppTheme: MantineThemeOverride = {
  colors: {
    white: new Array(10).fill('#ffffff') as Tuple<string, 10>,
    black: new Array(10).fill('#000000') as Tuple<string, 10>,
    bloodred: [
      '#ffe3e3',
      '#ffb3b3',
      '#fd8181',
      '#fc504f',
      '#fc241e',
      '#e31205',
      '#b10a03',
      '#7f0402',
      '#640000',
      '#1e0000'
    ]
  },
  primaryColor: 'bloodred',
  fontFamily: '"Merriweather", serif'
};

const AppLayout = () => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={AppTheme}>
      <AppShell header={<Header />}>
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
};

export { AppLayout };
