import {
  Button,
  Center,
  FileInput,
  Flex,
  NumberInput,
  TextInput,
  Textarea,
  Title
} from '@mantine/core';

const CreateRecordView = () => {
  return (
    <form action="">
      <Flex direction="column" wrap="nowrap" w="100%" rowGap="sm">
        <Center>
          <Title order={3}>Register Movement</Title>
        </Center>
        <TextInput label="Name" withAsterisk />
        <Textarea label="Notes" />
        <NumberInput
          label="Amount"
          withAsterisk
          thousandsSeparator=","
          decimalSeparator="."
          precision={2}
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
        />
        <FileInput
          label="Add photo"
          accept="image/x-png,image/jpeg,image/gif"
        />
        <Button>Save</Button>
      </Flex>
    </form>
  );
};

export { CreateRecordView };
